import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import DropDown from "components/reusable/dropdown";
import { Loader } from "components/reusable/Loader";
import BubbleCancelIcon from "resources/img/icons/bubble-cancel.svg";
import BubbleCheckIcon from "resources/img/icons/bubble-check.svg";
import CloseIcon from "resources/img/icons/close.svg";
import MastercardIcon from "resources/img/mastercard_logo.svg";
import VerveIcon from "resources/img/verve-logo.png";
import VisaIcon from "resources/img/visa_logo.svg";
import { fetchPaymentStatus, fundWallet } from "util/api_util";
import {
  formatCurrency,
  getCountry,
  getCurrencyCountry,
  getCurrencySymbol,
  isEmpty,
} from "util/format_helpers";
import { CurrencyInput } from "./currency_input";
import { RippleLoader } from "./ripple_loader";

/* global dlocal */
const dlocalInputStyle = {
  base: {
    fontSize: "14px",
    color: "#101828",
    "::placeholder": {
      color: "#797979",
    },
  },
  autofilled: {
    color: "#101828",
  },
};

const countryDropdown = [
  {
    label: "Kenya",
    value: "KE",
  },
  {
    label: "Nigeria",
    value: "NG",
  },
  {
    label: "South Africa",
    value: "ZA",
  },
];
const codeTitle = {
  100: "Payment Pending",
  300: "Payment Rejected",
  200: "Funds Added Successfully!",
};
const cardTypeToIcon = {
  visa: VisaIcon,
  mastercard: MastercardIcon,
  verve: VerveIcon,
};

const host = window.location.hostname;
const isProd =
  host.includes("portal.usesmileid.com") ||
  host.includes("portal.smileidentity.com");

const defaultError = {
  firstName: undefined,
  lastName: undefined,
  documentId: undefined,
  number: undefined,
  expiration: undefined,
  cvv: undefined,
};

export function DLocalPaymentFlow({
  show,
  apiKey,
  onCancel,
  onComplete,
  amount,
  currency,
  invoiceId,
  paymentMethods,
  partnerId,
}) {
  const [paymentState, setPaymentState] = useState("");
  const [params, setParams] = useState({});
  const [message, setMessage] = useState();
  const [title, setTitle] = useState();
  useLayoutEffect(() => {
    if (show) {
      document.body.classList.add("dlocal-modal-open");
    } else {
      document.body.classList.remove("dlocal-modal-open");
    }
  }, [show]);

  if (!show) {
    return null;
  }

  if (paymentState === "success") {
    const value = formatCurrency(amount, currency);
    const msg = message || `You added ${value} to your Smile ID wallet`;
    return (
      <PaymentModalMessage
        onClick={onComplete}
        title={title || "Funds Added Successfully!"}
        message={msg}
      />
    );
  }

  if (paymentState === "pending") {
    return (
      <PendingPaymentModal
        currency={currency}
        amount={amount}
        onClick={(status) => {
          if (status === "SUCCESS") {
            onComplete();
          } else {
            setMessage();
            setPaymentState();
          }
        }}
        params={params}
      />
    );
  }

  if (paymentState === "error") {
    return (
      <PaymentModalMessage
        error
        onClick={() => {
          setMessage();
          setPaymentState();
        }}
        title={title || "Failed to Top-up Wallet!"}
        message={message}
        btnLabel="Try again"
      />
    );
  }

  const getOnError = (status) => (message, code) => {
    setPaymentState(status);
    setMessage(message);
    if (code) setTitle(codeTitle[code]);
  };

  return (
    <div className="modal-backdrop">
      <div className="smile-modal modal-style dlocal-modal">
        <div className="header">
          <h1 className="header__title">Add Funds</h1>
          <img onClick={onCancel} src={CloseIcon} alt="" />
        </div>
        <SmartFields
          partnerId={partnerId}
          paymentMethods={paymentMethods}
          onComplete={getOnError("success")}
          onError={getOnError("error")}
          onPending={(data) => {
            setParams(data);
            setPaymentState("pending");
          }}
          apiKey={apiKey}
          amount={amount}
          currency={currency}
          invoiceId={invoiceId}
        />
      </div>
    </div>
  );
}

function SmartFields({
  apiKey,
  amount,
  currency,
  invoiceId,
  onComplete,
  onError,
  onPending,
  partnerId,
  paymentMethods = [],
}) {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [dLocalInstance, setDLocalInstance] = useState();
  const [firstName, setFirstname] = useState();
  const [lastName, setLastname] = useState();
  const [documentId, setDocumentId] = useState();
  const [phone, setPhone] = useState();
  const country = getCurrencyCountry(currency);
  const [cvv, setCVV] = useState();
  const [mode, setMode] = useState(
    paymentMethods.length === 0 ? "NEW_CARD" : "EXIST_CARD",
  );
  const [paymentOption, setPaymentOption] = useState();
  const [cardError, setCardError] = useState(defaultError);

  const createToken = async () => {
    try {
      const result = await dLocalInstance.createToken(cvv, {
        name: `${firstName} ${lastName}`,
      });
      return { success: true, token: result.token };
    } catch (e) {
      const { error } = e;
      if (!error?.param) {
        throw new Error(error?.message || e.message);
      }
      return { success: false, ...error };
    }
  };

  const validateInput = () => {
    const errors = { ...defaultError };
    if (mode === "NEW_CARD") {
      if (isEmpty(firstName)) {
        errors.firstName = "First name is required";
      }
      if (isEmpty(lastName)) {
        errors.lastName = "Last name is required";
      }
    }
    if (
      mode === "BANK_TRANSFER" &&
      country === "KE" &&
      !/^\+?[0-9]{11,13}$/.test(phone)
    ) {
      errors.phone = `Please write the country code followed by 9 digits: +254xxxxxxxxx`;
    }
    if (country === "ZA" && !/^[0-9]{13}$/.test(documentId)) {
      errors.documentId = `Invalid Nation ID`;
    }

    setCardError({ ...errors });
    return Object.values(errors).find((_) => _ !== undefined) === undefined;
  };

  const onSubmit = async () => {
    try {
      if (!validateInput()) {
        return;
      }
      setProcessing(true);
      const fundWalletParams = {
        amount, // NOTE: native currency, not always USD
        currency,
        invoiceId,
        country,
        documentId,
        partnerId,
        phone,
        name:
          firstName || lastName
            ? `${firstName ?? ""} ${lastName ?? ""}`.trim()
            : undefined,
        paymentMethod: mode === "BANK_TRANSFER" ? "BANK_TRANSFER" : "CARD",
      };

      if (mode === "EXIST_CARD" && paymentOption !== undefined) {
        fundWalletParams.cardId = paymentOption.value;
      } else if (mode === "NEW_CARD") {
        const { success, ...result } = await createToken();
        if (!success) {
          setProcessing(false);
          setCardError({ ...defaultError, [result.param]: result.message });
          return;
        }
        fundWalletParams.dlocalToken = result.token;
      }

      const resp = await fundWallet(fundWalletParams);
      setProcessing(false);

      if (!resp.success) {
        return onError(resp.error, resp.code);
      }
      if (resp.code === 100) {
        return onPending({ ...resp, mode });
      }
      onComplete(resp.message, resp.code);
    } catch (e) {
      let errorMessage =
        "Unable to process payment. Please try again, or contact your account manager or support@usesmileid.com for assistance.";
      if (e?.error?.message) {
        errorMessage = e?.error?.message;
      }
      onError(errorMessage);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    loadSmartField(() => {
      setLoading(false);
      // dlocal variable becomes available at the execution of this script.
      setDLocalInstance(dlocal(apiKey));
    });
  }, [apiKey]);

  useLayoutEffect(() => {
    if (!dLocalInstance || mode !== "NEW_CARD") return;

    const fields = dLocalInstance.fields({
      locale: "en",
      country,
    });

    const cardNumber = fields.create("pan", {
      style: dlocalInputStyle,
      placeholder: "Card number",
    });

    const expiration = fields.create("expiration", {
      style: dlocalInputStyle,
      placeholder: `MM/YY`,
    });

    const cvv = fields.create("cvv", {
      style: dlocalInputStyle,
      placeholder: "CVV",
    });
    setCVV(cvv);

    cardNumber.mount(document.getElementById("cardNumber"));
    expiration.mount(document.getElementById("expiration"));
    cvv.mount(document.getElementById("cvv"));
  }, [dLocalInstance, mode]);

  if (loading) {
    return (
      <section className="dlocal-modal__form center">
        <Loader size="xl" />
      </section>
    );
  }

  return (
    <section className="dlocal-modal__form">
      <div className="container">
        <label htmlFor="amount">Payment Amount</label>
        <CurrencyInput
          disabled
          id="amount"
          value={formatCurrency(amount, currency)}
          currency={currency}
        />
      </div>
      {country === "ZA" && (
        <div className="field">
          <label htmlFor="documentId">National ID</label>
          <input
            id="documentId"
            className="input"
            type="text"
            placeholder="National ID"
            required
            autoComplete="document_id"
            value={documentId ?? ""}
            onChange={(e) => setDocumentId(e.target.value)}
          />
          <div>
            {cardError.documentId && (
              <small style={{ color: "red" }}>{cardError.documentId}</small>
            )}
          </div>
        </div>
      )}
      {mode === "EXIST_CARD" && (
        <div className="field select-wrapper">
          <label>Select Payment Method</label>
          <DropDown
            className="smile-dropdown"
            selectedOption={paymentOption}
            options={paymentMethods.map((pm) => ({
              label: `****${pm.last_4}`,
              value: pm.card_id,
              icon: cardTypeToIcon[pm.card_type],
            }))}
            handleOptionSelection={(option) => setPaymentOption(option)}
          />
          <div>
            <button
              type="button"
              className="btn btn--link"
              onClick={() => {
                setMode("NEW_CARD");
                setPaymentOption(undefined);
              }}
            >
              Add new payment instrument
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn--link"
              onClick={() => setMode("BANK_TRANSFER")}
            >
              {country === "KE" ? "Pay with M-Pesa" : "Bank transfer"}
            </button>
          </div>
        </div>
      )}
      {mode === "NEW_CARD" && (
        <div className="container row">
          <div className="field half">
            <label htmlFor="firstName">First name</label>
            <input
              id="firstName"
              className="input"
              type="text"
              required
              autoComplete="first_name"
              value={firstName ?? ""}
              onChange={(e) => setFirstname(e.target.value)}
            />

            <div>
              {cardError.firstName && (
                <small style={{ color: "red" }}>{cardError.firstName}</small>
              )}
            </div>
          </div>
          <div className="field half">
            <label htmlFor="lastName">Last name</label>
            <input
              id="lastName"
              className="input"
              type="text"
              required
              autoComplete="last_name"
              value={lastName ?? ""}
              onChange={(e) => setLastname(e.target.value)}
            />
            <div>
              {cardError.lastName && (
                <small style={{ color: "red" }}>{cardError.lastName}</small>
              )}
            </div>
          </div>
        </div>
      )}

      {mode === "BANK_TRANSFER" && country === "KE" && (
        <div className="field">
          <label htmlFor="phone">Phone</label>
          <input
            id="phone"
            className="input"
            type="text"
            placeholder="+254xxxxxxxxx"
            required
            autoComplete="phone"
            value={phone ?? ""}
            onChange={(e) => setPhone(e.target.value)}
          />
          <div>
            {cardError.phone && (
              <small style={{ color: "red" }}>{cardError.phone}</small>
            )}
          </div>
          {paymentMethods.length > 0 && (
            <div>
              <button
                type="button"
                className="btn btn--link"
                onClick={() => setMode("EXIST_CARD")}
              >
                Use saved payment instrument
              </button>
            </div>
          )}
          <div>
            <button
              type="button"
              className="btn btn--link"
              onClick={() => setMode("NEW_CARD")}
            >
              Add new payment instrument
            </button>
          </div>
        </div>
      )}

      {mode === "NEW_CARD" && (
        <>
          <div className="field">
            <label htmlFor="cardNumber">Card number</label>
            <div className="dlocal-input-container">
              <div id="cardNumber" />
            </div>
            <div>
              {cardError.number && (
                <small style={{ color: "red" }}>{cardError.number}</small>
              )}
            </div>
          </div>
          <div className="container row">
            <div className="field half">
              <label htmlFor="expiration">Expiration</label>
              <div className="dlocal-input-container">
                <div id="expiration" />
              </div>

              <div>
                {cardError.expiration && (
                  <small style={{ color: "red", fontSize: "9px" }}>
                    {cardError.expiration}
                  </small>
                )}
              </div>
            </div>
            <div className="field half">
              <label htmlFor="cvv">CVV</label>
              <div className="dlocal-input-container">
                <div id="cvv" />
              </div>

              <div>
                {cardError.cvv && (
                  <small style={{ color: "red", fontSize: "9px" }}>
                    {cardError.cvv}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="field select-wrapper">
            <label>Country</label>
            <DropDown
              disabled
              className="smile-dropdown"
              label={getCountry(country)}
              selectedOption={country}
              options={countryDropdown}
              handleOptionSelection={() => {}}
            />
            {paymentMethods.length > 0 && (
              <div>
                <button
                  type="button"
                  className="btn btn--link"
                  onClick={() => setMode("EXIST_CARD")}
                >
                  Use saved payment instrument
                </button>
              </div>
            )}
            <div>
              <button
                type="button"
                className="btn btn--link"
                onClick={() => setMode("BANK_TRANSFER")}
              >
                {country === "KE" ? "Pay with M-Pesa" : "Bank transfer"}
              </button>
            </div>
          </div>
          <p>
            <small>
              By confirming your payment, you allow us to charge your card for
              this payment and future payments.
            </small>
          </p>
        </>
      )}
      <div>
        <button
          disabled={
            (mode === "EXIST_CARD" && paymentOption === undefined) || processing
          }
          id="pay_button"
          onClick={onSubmit}
          className="btn btn-primary fund-wallet-btn"
          type="button"
        >
          {processing && (
            <>
              <Loader />
              &nbsp;&nbsp;
            </>
          )}
          Make Payment
        </button>
      </div>
    </section>
  );
}

export function PaymentModalMessage({
  onClick,
  title,
  message,
  error = false,
  btnLabel = "Continue",
}) {
  return (
    <div className="modal-backdrop legacy">
      <div className="smile-modal modal-style filter-modal">
        <div className="filter-modal__success">
          <img
            className="icon--x-large"
            src={error ? BubbleCancelIcon : BubbleCheckIcon}
            alt="close-icon"
          />
          <div className="success-message">
            <h2>{title}</h2>
            <p>{message}</p>
          </div>
          <div className="filter-modal__action-btn-group">
            <button onClick={onClick} className="btn btn-primary" type="button">
              {btnLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function PendingPaymentModal({ onClick, params, amount, currency }) {
  const [title, setTitle] = useState(
    codeTitle[params?.code] ?? "Pending payment",
  );
  const [message, setMessage] = useState(params?.message ?? "");
  const [error, setError] = useState(false);
  const [polling, running] = usePolling(async () => {
    const result = await fetchPaymentStatus({
      country: params.country,
      payment_ref: params.payment_ref,
      provider: "dlocal",
    });

    const cancelClicked = result?.data?.code === 4000;

    if (!result.success) {
      setError(result.error);
      return polling();
    }
    if (cancelClicked) {
      setTitle("Payment Cancelled");
      setMessage("Your payment has been cancelled.");
      setError(result?.data?.message);
      return polling();
    }

    const { data } = result;
    if (data.status?.toLowerCase() !== "pending") {
      const code = data.status_code;
      const value = `${getCurrencySymbol(currency)}${amount}`;
      const message = `You added ${value} to your Smile ID wallet`;
      setTitle(codeTitle[code]);
      const isApproved = code === "200";
      setMessage(isApproved ? message : data.status_detail);
      setError(!isApproved);
      polling(); // stop polling
    }
  }, 5000);

  useEffect(() => {
    polling(); // start polling
    if (params.redirect_url) {
      window.open(params.redirect_url, "_self");
    }
  }, []);

  return (
    <div className="modal-backdrop legacy">
      <div className="smile-modal modal-style filter-modal">
        <div className="filter-modal__success center">
          {!running && (
            <img
              className="icon--x-large"
              src={error ? BubbleCancelIcon : BubbleCheckIcon}
              alt="close-icon"
            />
          )}
          <div className="success-message">
            <h2>{title}</h2>
            {running && <RippleLoader />}
            <p>{message}</p>
          </div>
          {!running && (
            <div className="filter-modal__action-btn-group">
              <button
                onClick={() => {
                  if (onClick) {
                    onClick(error ? "FAILED" : "SUCCESS");
                  }
                }}
                className="btn btn-primary"
                type="button"
              >
                {error ? "Try again" : "Continue"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function usePolling(callback, delay) {
  const savedCallback = useRef();
  const intervalId = useRef(null);
  const [currentDelay, setDelay] = useState(null);

  const toggleRunning = useCallback(
    () => setDelay((currentDelay) => (currentDelay === null ? delay : null)),
    [delay],
  );

  const clear = useCallback(() => clearInterval(intervalId.current), []);

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (intervalId.current) clear();

    if (currentDelay !== null) {
      intervalId.current = setInterval(tick, currentDelay);
    }

    return clear;
  }, [currentDelay, clear]);

  return [toggleRunning, !!currentDelay];
}

function loadSmartField(callback) {
  const existingScript = document.getElementById("dLocalScript");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = isProd
      ? "https://js.dlocal.com/"
      : "https://js-sandbox.dlocal.com/";
    script.id = "dLocalScript";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
}
