import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { RouteComponentProps } from "react-router-dom";
import Select, { SingleValue } from "react-select";

import { buildState, buildUrl } from "util/api_util";
import { getJobTypes } from "util/selectors";
import { REVIEWS_HEADERS } from "util/table_headers";
import styles from "./reviews.module.css";
import ReviewsShow from "./reviews_show";

interface JobType {
  value: string;
  label: string;
}

const findSelectedJobType = (
  jobTypeValue: string,
  jobTypes: JobType[],
): JobType | null =>
  jobTypes.find((jobType) => jobType.value === jobTypeValue) || null;

interface ReviewsProps extends RouteComponentProps {
  removeReviewsState: () => void;
  fetchResources: (
    type: string,
    queryState: Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => Promise<void>;
  reviews: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  userType: string;
  partners: string[];
  displayEnvironment: string;
}

interface QueryState {
  job_type?: string;
  partner_id?: string;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const Reviews: React.FC<ReviewsProps> = ({
  removeReviewsState,
  fetchResources,
  reviews,
  userType,
  partners,
  displayEnvironment,
  history,
}) => {
  const [query, setQuery] = useState<QueryState>(() =>
    history && history.location ? buildState(history.location.search) : {},
  );
  const [jobTypes] = useState<JobType[]>(getJobTypes());
  const [selectedJobType, setSelectedJobType] = useState<JobType | null>(
    findSelectedJobType(query.job_type || "", jobTypes) || null,
  );

  useEffect(
    () => () => {
      removeReviewsState();
    },
    [removeReviewsState],
  );

  useEffect(() => {
    fetchReviews();
  }, [displayEnvironment]);

  const fetchReviews = () => {
    const queryState = buildState(history.location.search);
    return fetchResources("reviews", queryState);
  };

  const onInput = (field: string) => (option: SingleValue<JobType>) => {
    if (field === "job_type" && option) {
      setSelectedJobType(option);
    }

    const newQuery = {
      ...query,
      [field]: option?.value,
    };
    setQuery(newQuery);

    history.push(`/admin${buildUrl("reviews", newQuery)}`);
    fetchReviews();
  };

  const renderPartnerSelector = () => {
    if (userType !== "admin") return null;

    const partnerOptions = partners.map((partner) => ({
      value: partner.split(" - ")[0],
      label: partner,
    }));

    const selectedPartner = partnerOptions.find(
      (option) => option.value === query.partner_id,
    );

    return (
      <div className={classNames("legacy", styles.filtersContainer)}>
        <label className="head-link inline">Filter by</label>
        <Select<JobType>
          value={selectedPartner}
          onChange={onInput("partner_id")}
          options={partnerOptions}
          styles={{
            option: (provided) => ({ ...provided, color: "black" }),
          }}
          isClearable
          isLoading={partnerOptions.length === 0}
          placeholder="All Partners"
          isSearchable
        />
        <Select<JobType>
          value={selectedJobType}
          onChange={onInput("job_type")}
          options={jobTypes}
          styles={{
            option: (provided) => ({ ...provided, color: "black" }),
          }}
          isClearable
          isLoading={jobTypes.length === 0}
          placeholder="All Job Types"
          isSearchable
        />
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "newsmile-container__main--temporarily-white dark_bg theme-dark",
      )}
    >
      <div className={styles.partnerSelect}>
        {renderPartnerSelector()}
        <ReviewsShow
          data={reviews}
          headers={REVIEWS_HEADERS}
          reviews
          tableType="reviews"
          history={history}
          fetchReviews={fetchReviews}
        />
      </div>
    </div>
  );
};

export default Reviews;
